
	import Vue from "vue";
	import { isEmpty, isNull, isUndefined, isNaN } from "lodash";
	// @ts-ignore
	import { ListItemDataCreate } from "@/interfaces/list_items";
	import CardTextField from "../../../../components/Content/CardTextField.vue";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
import { mapActions } from "vuex";

	export default Vue.extend({
		name: "TableListModelTwo",
		props: {
			headers: {
				type: Array,
				default: [],
			},
			items: {
				type: Array,
				default: [],
			},
			title: {
				type: String,
				default: "",
			},
			customList: {
				type: Object,
				default: {},
			}
		},
		components: {
			CardTextField,
		},
		data: () => ({
			records: Array,
			entity: {} as ListItemDataCreate,
			filter: {
				id: {
					value: "",
					order: "asc",
				},
				name: {
					value: "",
					order: "asc",
				},
				latitude: {
					value: "",
					order: "asc",
				},
				longitude: {
					value: "",
					order: "asc",
				},
				radius: {
					value: "",
					order: "asc",
				},
				value: {
					value: "",
					order: "asc",
				},
			},
		}),

		created() {
			this.records = this.initialicerecords(this.items);
		},

		mounted() {
			this.records = this.initialicerecords(this.items);
		},

		computed: {
			filteredData() {
				this.filtered = this.items;
				return this.filtered;
			},
			getRules() {
				return {
					required: [(v: any) => Boolean(v) || this.$t("fieldRequired")],
					lat: [
						(v: any) => Boolean(v) || this.$t("fieldRequired"),
						(v: number) => !isNaN(v) || this.$t("must-be-numeric"),
						(v: number) => v >= -90 || this.$t("min", { min: -90 }),
						(v: number) => v <= 90 || this.$t("max", { max: 90 }),
					],
					long: [
						(v: any) => Boolean(v) || this.$t("fieldRequired"),
						(v: number) => !isNaN(v) || this.$t("must-be-numeric"),
						(v: number) => v >= -180 || this.$t("min", { min: -180 }),
						(v: number) => v <= 180 || this.$t("max", { max: 180 }),
					],
					value: [
						(v: number) => !isNaN(v) || this.$t("must-be-numeric"),
						(v: number) => v >= 0 || this.$t("min", { min: 0 }),
						(v: number) => v <= 100 || this.$t("max", { max: 100 }),
					],
					radius_km: [
						(v: any) => Boolean(v) || this.$t("fieldRequired"),
						(v: number) => !isNaN(v) || this.$t("must-be-numeric"),
						(v: number) => v >= 0.001  || this.$t("min", { min: 0.001  }),
						(v: number) => v <= 50 || this.$t("max", { max: 50 }),
					],
				};
			},
		},

		methods: {
		...mapActions("loading", ["setLoadingData"]),
			setNotification(notification: Notification) {
				return this.$store.dispatch(
					"proccess/setNotification",
					notification,
					{ root: true }
				);
			},
			redirectTo() {
				this.setNotification({ title: "", message: "", type: "" });
				this.$router.push({ name: "CustomList" });
			},
			initialicerecords(oldRecords) {
				return oldRecords;
			},
			validateEntity(entity: any): boolean {
				
				if( isUndefined(entity) || isNull(entity) ){
					return false;
					//entity = {errors: ["Item cannot be empty."]};
				}//else{entity.errors = new Array;}

				if( isUndefined(entity.latitude) || isNull(entity.latitude)  || entity.latitude == "" ){
					return false;
					//entity.errors?.push(this.$t("fieldRequired"));
				}else{
					let tempotalValue = parseFloat(entity.latitude);
					if( isNaN(tempotalValue) || (tempotalValue < -90 ) ){
						return false;
						//entity.errors?.push(this.$t("min", { min: -90 }));
					}else if( isNaN(tempotalValue) || tempotalValue > 90 ){
						//entity.errors?.push(this.$t("min", { min: 90 }));
						return false;
					}
				}

				if( isUndefined(entity.longitude) || isNull(entity.longitude)  || entity.longitude == "" ){
					return false;
					//entity.errors?.push(this.$t("fieldRequired"));
				}else{
					let tempotalValue = parseFloat(entity.longitude);
					if( isNaN(tempotalValue) || (tempotalValue < -180 ) ){
						return false;
						//entity.errors?.push(this.$t("min", { min: -180 }));
					}else if( isNaN(tempotalValue) || tempotalValue > 180 ){
						return false;
						//entity.errors?.push(this.$t("min", { min: 180 }));
					}
				}

				if( !isUndefined(entity.value) ){
					let tempotalValue = parseFloat(entity.value);
					if( isNaN(tempotalValue) ){
						return false;
						//entity.errors?.push(this.$t("must-be-numeric"));
					}else if( tempotalValue < 0 ){
						return false;
						//entity.errors?.push(this.$t("min", { min: 0 }));
					}else if( tempotalValue > 100 ){
						return false;
						//entity.errors?.push(this.$t("max", { max: 100 }));
					}
				}

				if( isUndefined(entity.radius) ){
					return false;
					//entity.errors?.push(this.$t("fieldRequired"));
				}else{
					let tempotalValue = parseFloat(entity.radius);
					if( isNaN(tempotalValue) ){
						return false;
						//entity.errors?.push(this.$t("must-be-numeric"));
					}else if( tempotalValue < 0.001 ){
						return false;
						//entity.errors?.push(this.$t("min", { min: 0 }));
					}else if( tempotalValue > 50 ){
						return false;
						//entity.errors?.push(this.$t("max", { max: 50 }));
					}
				}

				/* if( entity && entity.errors ){
					return !( entity.errors.length > 0);
				}else{return false;} */
				return true;
			},
			validate(entities: any[]): boolean {
				if( isUndefined(entities) || isNull(entities) || isEmpty(entities) ){return false;}
				let valid = true;
				let index = 0;
				while ( index < entities.length && valid ) {
					valid = this.validateEntity(entities[index]);
					entities[index].status = valid;
					index++;
				}
				return valid;
			},
			async handleSubmit() {
				try {
					if ( !(await this.validate(this.records)) ) return;
					this.setLoadingData(TypeLoading.loading)
					let index: number = 0;
					while ( index < this.records.length ) {
						let result = await this.handleAction(this.records[index]);
						if( result ){
							this.records[index].id = result?.id;
							this.records[index].value = result?.value;
							this.records[index].name = result?.name;
							this.records[index].edited = false;
						}
						index++;
					}
					await this.setLoadingData();
				} catch (error) {
					await this.setLoadingData();
				}
			},
			handleCancel() {
				this.$router.push({ name: "CustomListIndex" });
			},
			handleAddItem() {
				let item: any;
				item = {
					id: undefined,
					latitude: undefined,
					longitude: undefined,
					radius: undefined,
					value: undefined,
					name: undefined,
					actions: [],
					edited: false,
					status: undefined,
				};
				let result = this.validate(this.records);
				if( result || this.records.length == 0 ){this.records.push(item);}
			},
			async handleDelete(index) {
				/*let result = await this.delete(this.records[index].id);
				if( result && result.success ){
					this.records.splice(index, 1);
				}*/
				this.records.splice(index, 1);
			},
			/*async dispatchEntities(id: number) {
				return this.$store.dispatch("listItem/getAll", id, {
					root: true,
				});
			},*/
			async handleAction(item: any) {
				this.entity = {
					id: item?.id,
					custom_list_id: this.customList?.id,
					list_item: {
						lat: parseFloat(item?.latitude),
						long: parseFloat(item?.longitude),
						radius_km: parseFloat(item?.radius),
					},
					name: item?.name?.toString()?.trim(),
					value: parseFloat(item?.value),
					edited: item?.edited,
				} as any;
				let result:any = undefined;
				if( this.entity && this.entity?.id > 0 ){
					if( this.entity.edited ){
						result = await this.$store.dispatch("listItem/update", {listItem: this.entity, customListType: "lat_long"}, {
							root: true,
						});
					}
				}else if ( this.entity ) {
					result = await this.$store.dispatch("listItem/create", {listItem: this.entity, customListType: "lat_long"}, {
						root: true,
					});
				}
				return result;
			},
			async delete(id: number) {				
				if( id && id > 0 ){
					return await this.$store.dispatch("listItem/delete", id, {
						root: true,
					});
				}
				return {success: true};
			},
			hasChanged(entity: any){
				entity.edited = true;
				return entity;
			},
		},
	});
