
	import Vue from "vue";
	import { Type } from "../../../../interfaces/list";
	import Alertize from "../../../../components/Alertize.vue";
	import CreateItem from "./../../Items/Create/create.vue";
	import CustomListForm from "../CustomListForm.vue";
	import { CustomListDataCreate, CustomList } from "../../../../interfaces/custom_list";
	import { last, toNumber } from "lodash";
	// @ts-ignore
	import { TypeLoading } from "@/interfaces/loading";

	export default Vue.extend({
		name: "ListShow",
		props: {},
		components: { Alertize, CreateItem, CustomListForm },
		data: () => ({
			title: "Show",
			message: "",
			type: "info",
			valid: false,

			option_selected: "manually",
			custom_list: { active: true } as CustomListDataCreate,
			type_selected: {} as Type,
			model_view: undefined,
		}),
		async created() {
			await this.setLoadingData(TypeLoading.loading);
			let typeIdSetted = undefined;
			await this.dispatchTypes();
			await this.dispatchShowCustomList().then((result: CustomList) => {
				this.custom_list = result;
				typeIdSetted = this.custom_list?.custom_list_type_id;
			});
			await this.dispatchListItems();
			let customTypes = this.getCustomTypes;
			if( Array.isArray(customTypes) ){
				let type = customTypes.find( t => {return t.id == typeIdSetted});
				if( type ){
					await this.dispatchModelView(type);
				}
			}
			await this.setLoadingData();
		},
		async mounted() {},
		computed: {
			getListItems(): any[] {
				const elements = this.$store.state.listItem.entities;
				return elements;
			},
			getCustomList() {
				return this.$store.state.custom_list.custom_list;
			},
			getModelView() {
				return this.$store.state.custom_list.model_view;
			},
			enableRadiusField(): Boolean {
				return (
					Object.keys(this.type_selected).length > 0 &&
					this.type_selected.key === "lat_long"
				);
			},
			hasTypeSelected(): Boolean {
				return Object.keys(this.type_selected).length > 0;
			},
			showItems(): Boolean {
				return (
					this.option_selected === "manually"
				);
			},
			getId() {
				let pathArray: String[] = this.$route.path.split("/");
				const lastItem = last(pathArray);
				return !isNaN(toNumber(lastItem)) ? toNumber(lastItem) : NaN;
			},
			getCustomTypes(): Type[] {
				return this.$store.state.custom_list.types;
			},
		},
		methods: {
			setNotification(notification: Notification) {
				return this.$store.dispatch(
					"proccess/setNotification",
					notification,
					{ root: true }
				);
			},
			redirectTo() {
				this.setNotification({ title: "", message: "", type: "" });
				this.$router.push({ name: "CustomListIndex" });
			},

			async validate() {
				let form = this.$refs.form;
				const valid = await form.validate();
				return await valid;
			},
			async handleSubmit() {
				if (!(await this.validate())) return;
				await this.dispatchCreateCustomList();
			},
			handleCancel() {
				this.$router.push({ name: "CustomListIndex" });
			},
			resetModelView() {
				this.$store.state.custom_list.model_view = "";
			},
			async dispatchTypes() {
				return this.$store.dispatch("custom_list/getTypes", {
					root: true,
				});
			},
			async dispatchListItems() {
				//let id_selected = this.custom_list.id;
				if (isNaN(this.custom_list.id)) return [];
				return this.$store.dispatch("listItem/getAllByCustomId", this.custom_list, {
					root: true,
				});
			},
			async dispatchModelView(type: Type) {
				return this.$store.dispatch(
					"custom_list/getViewByTypeSelected",
					type,
					{
						root: true,
					}
				);
			},
			async dispatchCreateCustomList() {
				return this.$store.dispatch(
					"custom_list/createCustomList",
					this.custom_list,
					{
						root: true,
					}
				);
			},
			async dispatchShowCustomList() {
				if (isNaN(this.getId)) return;
				return this.$store.dispatch("custom_list/show", this.getId, {
					root: true,
				});
			},
			async updateTypeSelected(data: Type) {
				this.resetModelView();
				this.type_selected = data;

				switch (this.option_selected) {
					case "manually":
						await this.dispatchModelView(data);
						break;
					case "upload":

						break;
					default:
						break;
				}
			},
		},

		watch: {
			async option_selected(val, old) {
				switch (val) {
					case "manually":
						await this.dispatchModelView(this.type_selected);
						break;
					default:
						break;
				}
			},
		},
	});
